import classNames from 'classnames'
import React, {PropsWithChildren} from 'react'
import {Badge as TPABadge} from 'wix-ui-tpa/cssVars'
import {classes} from './badge.st.css'
import {BadgeProps} from './interfaces'

export const Badge = ({className, children, dataHook}: PropsWithChildren<BadgeProps>) => {
  return (
    <TPABadge className={classNames(classes.root, className)} data-hook={dataHook}>
      {children}
    </TPABadge>
  )
}
