import {isDonationTicketDefinition} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {Text} from 'wix-ui-tpa/cssVars'
import {useCurrencyFormatter} from '../../../../../../../../commons/hooks/currency'
import {useMobileFontOverrides} from '../../../../../../../../commons/hooks/use-mobile-font-overrides'
import {usePageStateSelector} from '../../../../../hooks/state'
import {getEvent} from '../../../../../selectors/event'
import {getTax} from '../../../../../selectors/tax-and-fee'
import {classes} from './tax.st.css'

export interface TaxProps {
  ticket: wix.events.ticketing.TicketDefinition
  className?: string
  overridePrice?: string
}

export const Tax = ({ticket, className, overridePrice}: TaxProps) => {
  const {t} = useTranslation()
  const event = usePageStateSelector(getEvent)
  const abstract = isDonationTicketDefinition(ticket) && !overridePrice
  const {getFormattedMoney} = useCurrencyFormatter()
  const tax = getTax({event, ticket, t, overridePrice, abstract, getFormattedMoney})
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  const {isMobile} = useEnvironment()

  if (!tax) {
    return null
  }

  return (
    <Text
      tagName="p"
      className={classNames(classes.tax, className, {[classes.mobile]: mobileFontOverridesEnabled && isMobile})}
      data-hook={DH.TAX_LABEL}
      aria-label={t('mobile.thanks.tax', {tax})}
    >
      {tax}
    </Text>
  )
}
