import {
  getEventTicketLimitPerOrder,
  hasPricingOptions,
  hasSeatingPlan,
  isRegistrationClosedVisible,
  saleStartedAndHasTickets,
} from '@wix/wix-events-commons-statics'
import React, {useCallback} from 'react'
import {usePageActions, usePageStateSelector} from '../../../../../hooks/state'
import {getEvent} from '../../../../../selectors/event'
import {getSelectedTicketQuantity, getSelectedTicketsQuantity} from '../../../../../selectors/selected-tickets'
import {Quantity} from '../quantity'

export const TicketQuantity = ({
  ticket,
  className,
}: {
  ticket: wix.events.ticketing.TicketDefinition
  className?: string
}) => {
  const event = usePageStateSelector(getEvent)
  const maxTicketsPerReservation = getEventTicketLimitPerOrder(event)
  const registrationClosed = isRegistrationClosedVisible(event)
  const withSeatingPlan = hasSeatingPlan(event)
  const saleStarted = saleStartedAndHasTickets(ticket)
  const withPricingOptions = hasPricingOptions(ticket)
  const {limitPerCheckout, id} = ticket
  const totalSelectedQuantity = usePageStateSelector(state => getSelectedTicketsQuantity(state))
  const selectedQuantity = usePageStateSelector(state => getSelectedTicketQuantity(state, id))

  const {selectTicket} = usePageActions()

  const onSelectCount = useCallback((count: number) => {
    selectTicket({ticketId: id, count})
  }, [])

  if (!saleStarted || withPricingOptions || withSeatingPlan || registrationClosed) {
    return null
  }

  const availableTicketsQuantity = maxTicketsPerReservation - totalSelectedQuantity
  const max = Math.min(availableTicketsQuantity + selectedQuantity, limitPerCheckout)

  return (
    <div className={className}>
      <Quantity max={max} value={selectedQuantity} onSelectCount={onSelectCount} />
    </div>
  )
}
