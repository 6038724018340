import {
  GetFormattedMoney,
  getTaxConfig,
  getTaxRateLabel,
  getTicketServiceFee,
  getTicketTax,
  hasPricingOptions,
  isDonationTicketDefinition,
  isFreeTicketDefinition,
  isTaxAdded,
} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import {isWixFeeTypeIsAdded} from './tickets'

interface TaxProps {
  event: ExtendedEvent
  ticket: wix.events.ticketing.TicketDefinition
  t: TFunction
  overridePrice?: string
  abstract?: boolean
  getFormattedMoney: GetFormattedMoney
  planTicketsDesignEnabled?: boolean
}

export const getTax = ({event, ticket, t, overridePrice, abstract = false, getFormattedMoney}: TaxProps) => {
  const taxConfig = getTaxConfig(event) as wix.events.TaxConfig
  const isDonation = isDonationTicketDefinition(ticket)
  const visible = Boolean(taxConfig) && !isFreeTicketDefinition(ticket) && (!isDonation || taxConfig.appliesToDonations)

  if (!visible) {
    return null
  }

  if (!isTaxAdded(taxConfig.type)) {
    return t('tax.included', taxConfig)
  } else if (abstract) {
    return `+${getTaxRateLabel(taxConfig)} ${taxConfig.name}`
  } else {
    return `+${getTicketTax(ticket, taxConfig, overridePrice, getFormattedMoney)} ${taxConfig.name}`
  }
}

export const getFee = ({
  event,
  ticket,
  t,
  overridePrice,
  abstract = false,
  getFormattedMoney,
  planTicketsDesignEnabled = false,
}: TaxProps) => {
  const taxConfig = getTaxConfig(event) as wix.events.TaxConfig

  if (!isWixFeeTypeIsAdded(ticket) || isFreeTicketDefinition(ticket)) {
    return null
  }

  if (abstract) {
    return planTicketsDesignEnabled ? `+${t('checkout_serviceFee_v2')}` : `+${t('checkout_serviceFee')}`
  } else {
    return t(planTicketsDesignEnabled ? 'checkout_plusServiceFee_v2' : 'checkout_plusServiceFee', {
      fee: getTicketServiceFee(ticket, taxConfig, overridePrice, getFormattedMoney),
    })
  }
}

export const getFees = ({
  event,
  ticket,
  t,
  getFormattedMoney,
  overridePrice,
  noServiceFee,
  planTicketsDesignEnabled = false,
}: {
  event: ExtendedEvent
  ticket: wix.events.ticketing.TicketDefinition
  t: TFunction
  getFormattedMoney: GetFormattedMoney
  overridePrice?: string
  noServiceFee?: boolean
  planTicketsDesignEnabled?: boolean
}) => {
  const lacksPriceOverride = (isDonationTicketDefinition(ticket) || hasPricingOptions(ticket)) && !overridePrice

  if (lacksPriceOverride) {
    return getAbstractFees({event, ticket, t, getFormattedMoney, noServiceFee, planTicketsDesignEnabled})
  }

  return [
    getTax({event, ticket, t, overridePrice, getFormattedMoney}),
    noServiceFee ? undefined : getFee({event, ticket, t, overridePrice, getFormattedMoney, planTicketsDesignEnabled}),
  ]
    .filter(Boolean)
    .join(', ')
}

export const getAbstractFees = ({
  event,
  ticket,
  t,
  getFormattedMoney,
  noServiceFee,
  planTicketsDesignEnabled = false,
}: {
  event: ExtendedEvent
  ticket: wix.events.ticketing.TicketDefinition
  t: TFunction
  getFormattedMoney: GetFormattedMoney
  noServiceFee?: boolean
  planTicketsDesignEnabled?: boolean
}) =>
  [
    getTax({event, ticket, t, abstract: true, getFormattedMoney}),
    noServiceFee ? undefined : getFee({event, ticket, t, abstract: true, getFormattedMoney, planTicketsDesignEnabled}),
  ]
    .filter(Boolean)
    .join(', ')
