import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {useMobileFontOverrides} from '../../../../../../../../commons/hooks/use-mobile-font-overrides'
import {DonationInput} from '../../../../donation-input'
import {classes} from './donation.st.css'
import {DonationProps} from './interfaces'

export const Donation = ({...props}: DonationProps) => {
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  const {isMobile} = useEnvironment()

  return (
    <DonationInput
      {...props}
      className={classNames(classes.root, {[classes.mobile]: mobileFontOverridesEnabled && isMobile})}
    />
  )
}
