import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React, {useCallback, useMemo} from 'react'
import {Dropdown} from 'wix-ui-tpa/cssVars'
import {useMobileFontOverrides} from '../../../../../../../../commons/hooks/use-mobile-font-overrides'
import {usePageStateSelector} from '../../../../../hooks/state'
import {isRequestPending} from '../../../../../selectors/pending-requests'
import {classes} from './quantity.st.css'

interface QuantityProps {
  max: number
  value: number
  onSelectCount: (count: number) => void
}

export const Quantity = ({value, max, onSelectCount}: QuantityProps) => {
  const {t} = useTranslation()
  const reserveTicketsPending = usePageStateSelector(state => isRequestPending(state, 'RESERVE_TICKETS_REQUEST'))
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  const {isMobile} = useEnvironment()

  const _onSelectCount = useCallback(
    (count: number) => {
      if (reserveTicketsPending) {
        return
      }
      onSelectCount(count)
    },
    [onSelectCount],
  )

  const options = useMemo(
    () =>
      [...Array(max + 1).keys()].map(index => ({
        id: index.toString(),
        value: index.toString(),
        isSelectable: true,
      })),
    [max],
  )

  return (
    <Dropdown
      key={new Date().toISOString()}
      label={t('ticketsThankYou.quantity')}
      data-hook={DH.QUANTITY_PICKER}
      options={options}
      initialSelectedId={value.toString()}
      className={classNames(classes.quantity, {
        [classes.mobile]: mobileFontOverridesEnabled && isMobile,
      })} /* don't add custom classes to Dropdown, eg. for positioning, since it affects popover Portal too */
      upgrade
      onChange={selectedOption => _onSelectCount(Number(selectedOption.id))}
      appendTo={(e: Element) => e.getAttribute('data-hook') === DH.eventDetails}
      mobileNativeSelect
    />
  )
}
