import {hasSeatingPlan, isRegistrationClosed, saleStartedAndHasTickets} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {Text} from 'wix-ui-tpa/cssVars'
import {useMobileFontOverrides} from '../../../../../../../../commons/hooks/use-mobile-font-overrides'
import {usePageStateSelector} from '../../../../../hooks/state'
import {getEvent} from '../../../../../selectors/event'
import {OnSaleProps} from './interfaces'
import {classes} from './on-sale-label.st.css'

export const OnSaleLabel = ({ticket, className}: OnSaleProps) => {
  const {t} = useTranslation()
  const event = usePageStateSelector(getEvent)
  const withSeatingPlan = hasSeatingPlan(event)
  const registrationClosed = isRegistrationClosed(event)
  const onSale = saleStartedAndHasTickets(ticket)
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  const {isMobile} = useEnvironment()

  if (!onSale || !withSeatingPlan || registrationClosed) {
    return null
  }

  return (
    <Text
      className={classNames(classes.text, className, {[classes.mobile]: mobileFontOverridesEnabled && isMobile})}
      data-hook={DH.TICKET_GOES_ON_SALE}
      tagName="p"
    >
      {t('ticketsPicker_onSale')}
    </Text>
  )
}
