import {
  isEnded,
  isRegistrationClosed,
  saleEndedAndHasTickets,
  saleScheduledAndHasTickets,
} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import React from 'react'
import {usePageStateSelector} from '../../../../../hooks/state'
import {getEvent} from '../../../../../selectors/event'
import {Badge} from '../badge'
import {SaleEndedBadgeProps} from './index'

export const SaleEndedBadge = ({ticket, className}: SaleEndedBadgeProps) => {
  const {t} = useTranslation()

  const event = usePageStateSelector(getEvent)
  const registrationClosed = isRegistrationClosed(event)
  const eventEnded = isEnded(event)
  const saleEnded = saleEndedAndHasTickets(ticket)
  const saleScheduled = saleScheduledAndHasTickets(ticket)

  if ((saleScheduled && registrationClosed && eventEnded) || saleEnded) {
    return (
      <Badge className={className} dataHook={DH.TICKET_SALE_ENDED}>
        {t('ticketsPicker.saleEndedBadge')}
      </Badge>
    )
  }

  return null
}
