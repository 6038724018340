import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {Text} from 'wix-ui-tpa/cssVars'
import {useMobileFontOverrides} from '../../../../../../../../commons/hooks/use-mobile-font-overrides'
import {a11yLabelId} from '../../a11y'
import {classes} from './labeled.st.css'

interface LabeledProps {
  label: string
  labeledElementId: string
  children: (props: {ariaLabeledBy?: string}) => React.ReactNode
  className?: string
}

export const Labeled = ({children, labeledElementId, label, className}: LabeledProps) => {
  const {isMobile} = useEnvironment()
  const labelId = a11yLabelId(labeledElementId)
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()

  return (
    <div className={classNames({[classes.textAlignment]: !isMobile}, className)}>
      <Text
        className={classNames(classes.label, {[classes.mobile]: mobileFontOverridesEnabled && isMobile})}
        tagName="span"
        id={labelId}
        aria-hidden="true"
      >
        {label}
      </Text>
      <div className={classes.content}>{children({ariaLabeledBy: `${labelId} ${labeledElementId}`})}</div>
    </div>
  )
}
