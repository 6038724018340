import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import React from 'react'
import {useSettings} from '../../../../../hooks/settings'
import {settingsParams} from '../../../../../settingsParams'
import {Subtitle} from '../../../subtitle'

export const Header = () => {
  const settings = useSettings()
  const ticketsTitleText = settings.get(settingsParams.ticketsTitleText)
  return <Subtitle dataHook={DH.subtitleTickets} text={ticketsTitleText} compact />
}
