import {ChevronDown, ChevronUp} from '@wix/wix-ui-icons-common/on-stage'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React, {PropsWithChildren} from 'react'
import {TextButton} from 'wix-ui-tpa/cssVars'
import {useMobileFontOverrides} from '../../../../../../../../commons/hooks/use-mobile-font-overrides'
import {classes} from './expansion-button.st.css'
import {ExpansionButtonProps} from './interfaces'

export const ExpansionButton: React.FC<PropsWithChildren<ExpansionButtonProps>> = ({
  children,
  className,
  dataHook,
  expanded,
  onChange,
}) => {
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  const {isMobile} = useEnvironment()

  return (
    <TextButton
      data-hook={dataHook}
      aria-expanded={expanded}
      className={classNames(classes.root, className, {
        [classes.mobile]: mobileFontOverridesEnabled && isMobile,
      })}
      onClick={() => onChange(!expanded)}
    >
      {children}
      <div className={classes.chevron}>
        {expanded ? <ChevronUp className={classes.icon} /> : <ChevronDown className={classes.icon} />}
      </div>
    </TextButton>
  )
}
