import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import alignmentClasses from '../../../alignment/alignment.scss'
import {Tax} from '../tax'
import {WixFee} from '../wix-fee'
import s from './fees.scss'

export const Fees = ({
  ticket,
  overridePrice,
}: {
  ticket: wix.events.ticketing.TicketDefinition
  overridePrice?: string
}) => {
  const {isMobile} = useEnvironment()
  return (
    <div
      className={classNames({
        [s.desktopTaxFeeContainer]: !isMobile,
        [s.mobileTaxFeeContainer]: isMobile,
        [alignmentClasses.contentAlignmentJustify]: isMobile,
      })}
    >
      <Tax ticket={ticket} overridePrice={overridePrice} />
      <WixFee ticket={ticket} overridePrice={overridePrice} />
    </div>
  )
}
