import {getPricingOptions, hasPricingOptions} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import React, {useState} from 'react'
import {ExpansionButton} from '../fields/expansion-button'
import {PricingOption} from './pricing-option/pricing-option'
import s from './pricing-options.scss'

export const PricingOptions = ({ticket}: {ticket: wix.events.ticketing.Ticket}) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()
  const showPricingOptions = hasPricingOptions(ticket)
  const [expanded, setExpanded] = useState(false)

  if (!showPricingOptions) {
    return null
  }

  const pricingOptions = getPricingOptions(ticket)
  const alwaysVisibleOptions = pricingOptions.slice(0, 3)
  const expandableOptions = pricingOptions.slice(3)

  return (
    <div className={isMobile ? undefined : s.textAlignment}>
      <ul aria-label={t('a11y.pricingOptionsList', {ticketType: ticket.name})}>
        {alwaysVisibleOptions.map(pricingOption => (
          <PricingOption key={pricingOption.id} pricingOption={pricingOption} ticket={ticket} />
        ))}
      </ul>
      {expandableOptions.length ? (
        <ExpansionButton
          dataHook={DH.MORE_PRICE_OPTIONS_BUTTON}
          expanded={expanded}
          onChange={setExpanded}
          className={isMobile ? s.mobileMoreOptions : s.moreOptions}
        >
          {t('ticketsPicker.expandPricingOptionsButton', {count: expandableOptions.length})}
        </ExpansionButton>
      ) : null}
      <ul
        aria-label={t('a11y.pricingOptionsListMorePrices', {ticketType: ticket.name})}
        style={{display: expanded ? 'block' : 'none'}}
      >
        {expandableOptions.map((pricingOption, index) => (
          <PricingOption key={pricingOption.id} pricingOption={pricingOption} ticket={ticket} noDivider={index === 0} />
        ))}
      </ul>
    </div>
  )
}
