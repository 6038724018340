import React from 'react'
import s from './price-quantity-container.scss'

export const PriceQuantityContainer = ({left, right}: {left: React.ReactNode; right: React.ReactNode}) => {
  return (
    <div className={s.container}>
      <div className={s.priceContainer}>{left}</div>
      <div className={s.quantityContainer}>{right}</div>
    </div>
  )
}
