import {getEventTicketLimitPerOrder, shouldTicketLimitTooltipShow} from '@wix/wix-events-commons-statics'
import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {SectionNotification} from 'wix-ui-tpa/cssVars'
import {usePageStateSelector} from '../../../../../hooks/state'
import {getEvent} from '../../../../../selectors/event'
import {getSelectedTicketQuantity, getSelectedTicketsQuantity} from '../../../../../selectors/selected-tickets'
import {classes} from './limit-reached-notification.st.css'

export const LimitReachedNotification = ({
  ticket,
  className,
}: {
  ticket: wix.events.ticketing.TicketDefinition
  className?: string
}) => {
  const {t} = useTranslation()
  const event = usePageStateSelector(getEvent)
  const maxTicketsPerReservation = getEventTicketLimitPerOrder(event)
  const selectedQuantity = usePageStateSelector(state => getSelectedTicketQuantity(state, ticket.id))
  const allSelectedTicketsQuantity = usePageStateSelector(state => getSelectedTicketsQuantity(state))
  const availableTicketsQuantity = maxTicketsPerReservation - allSelectedTicketsQuantity
  const show = shouldTicketLimitTooltipShow(availableTicketsQuantity, selectedQuantity)

  if (!show) {
    return null
  }

  return (
    <SectionNotification className={classNames(classes.root, className)} type="wired" size="compact">
      <SectionNotification.Text>
        {t('ticketLimitPerReservationReachedNotification', {count: maxTicketsPerReservation})}
      </SectionNotification.Text>
    </SectionNotification>
  )
}
