import {hasSeatingPlan} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import React from 'react'
import {usePageActions, usePageStateSelector} from '../../../../hooks/state'
import {getEvent, isCheckoutButtonDisabled} from '../../../../selectors/event'
import {FormEntry, SeatingPlanModal} from '../../../chunks'
import {RegistrationButtonSkin} from '../../../registration-buttons/registration-button-skin'
import {TotalPrice} from './total-price'

export const Footer = () => {
  const {isMobile} = useEnvironment()
  const checkoutButtonDisabled = usePageStateSelector(isCheckoutButtonDisabled)
  const withSeatingPlan = usePageStateSelector(state => hasSeatingPlan(getEvent(state)))
  const {checkout, openSeatingPlan} = usePageActions()
  const {t} = useTranslation()

  const handleOnClick = () => {
    if (withSeatingPlan) {
      SeatingPlanModal.preload()
      return openSeatingPlan()
    }

    FormEntry.preload()
    return checkout()
  }

  return (
    <>
      {withSeatingPlan ? null : <TotalPrice />}
      <RegistrationButtonSkin
        secondary={true}
        minWidth="100%"
        minHeight={isMobile ? undefined : '48px'}
        disabled={checkoutButtonDisabled}
        onClick={handleOnClick}
        dataHook={isMobile ? DH.CHECKOUT : DH.RESERVE_TICKETS_BUTTON}
        narrowerButton={isMobile}
        text={withSeatingPlan ? t('ticketsPicker_selectTickets') : t('checkout')}
      />
    </>
  )
}
