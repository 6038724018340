import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {Text} from 'wix-ui-tpa/cssVars'
import {useMobileFontOverrides} from '../../../../../../../../commons/hooks/use-mobile-font-overrides'
import {a11yTicketTypeId} from '../../a11y'
import {Labeled} from '../labeled'
import {classes} from './ticket-type.st.css'

export const TicketType = ({
  ticket,
  className,
}: {
  ticket: wix.events.ticketing.TicketDefinition
  className?: string
}) => {
  const {t} = useTranslation()
  const ticketId = a11yTicketTypeId(ticket)
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  const {isMobile} = useEnvironment()

  return (
    <Labeled label={t('viewer_ticketsPicker_ticketType')} labeledElementId={ticketId} className={className}>
      {({ariaLabeledBy}) => (
        <Text
          tagName="h3"
          id={ticketId}
          className={classNames(classes.name, {[classes.mobile]: mobileFontOverridesEnabled && isMobile})}
          data-hook={DH.NAME}
          aria-labelledby={ariaLabeledBy}
        >
          {ticket.name}
        </Text>
      )}
    </Labeled>
  )
}
