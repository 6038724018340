import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {Text} from 'wix-ui-tpa/cssVars'
import {useMobileFontOverrides} from '../../../../../../../../commons/hooks/use-mobile-font-overrides'
import {a11yPriceId} from '../../a11y'
import {Labeled} from '../labeled'
import {classes} from './price.st.css'

export const Price = ({
  label,
  id,
  price,
  extraAriaLabeledBy = '',
}: {
  label: string
  id: string
  price: string
  extraAriaLabeledBy?: string
}) => {
  const priceId = a11yPriceId(id)
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  const {isMobile} = useEnvironment()

  return (
    <Labeled label={label} labeledElementId={priceId}>
      {({ariaLabeledBy}) => (
        <Text
          tagName="span"
          id={priceId}
          className={classNames(classes.price, {[classes.mobile]: mobileFontOverridesEnabled && isMobile})}
          data-hook={DH.PRICE}
          aria-labelledby={`${extraAriaLabeledBy} ${ariaLabeledBy}`}
        >
          {price}
        </Text>
      )}
    </Labeled>
  )
}
