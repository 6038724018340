import {PricingOption as PricingOptionType} from '@wix/ambassador-wix-events-web/types'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {PriceQuantityContainer} from '../../desktop-tickets-picker/desktop-ticket/price-quantity-section/price-quantity-container'
import {PricingOptionPrice} from '../pricing-option-price'
import {PricingOptionQuantity} from '../pricing-option-quantity'
import s from './pricing-option.scss'

export interface PricingOptionProps {
  pricingOption: PricingOptionType
  ticket: wix.events.ticketing.TicketDefinition
  noDivider?: boolean
}

export const PricingOption = ({pricingOption, ticket, noDivider}: PricingOptionProps) => {
  const {isMobile} = useEnvironment()

  return (
    <li
      className={classNames(s.pricingOptionContainer, {
        [s.topDivider]: !noDivider,
        [s.mobile]: isMobile,
      })}
      data-hook={DH.TICKET_PRICING_OPTION}
    >
      {isMobile ? (
        <>
          <PricingOptionPrice ticket={ticket} pricingOption={pricingOption} />
          <PricingOptionQuantity className={s.mobileQuantityContainer} ticket={ticket} pricingOption={pricingOption} />
        </>
      ) : (
        <PriceQuantityContainer
          left={<PricingOptionPrice ticket={ticket} pricingOption={pricingOption} />}
          right={<PricingOptionQuantity ticket={ticket} pricingOption={pricingOption} />}
        />
      )}
    </li>
  )
}
