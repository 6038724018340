import React from 'react'

export interface InvoiceBreakdownOwnProps {
  containerClass?: string
  size?: InvoiceBreakdownSize
  divider?: React.ReactNode
  preliminary?: boolean
}

export interface InvoiceBreakdownProps extends InvoiceBreakdownRuntimeProps, InvoiceBreakdownOwnProps {}

export interface InvoiceBreakdownRuntimeProps {
  invoice: wix.events.ticketing.Invoice
  giftCardPaymentDetails?: wix.events.ticketing.GiftCardPaymentDetails[]
}

export enum InvoiceBreakdownSize {
  small,
  big,
}
